
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive
} from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { translate } from "@/hooks/TranslateService";
import { IIIInterface } from "@/store/modules/valored/IIIModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import AddCompanySettingModal from "@/components/modals/forms/AddCompanySettingModal.vue";
import bus from "vue3-eventbus";
import { IUser } from "@/store/modules/valored/UserModule";
import { translateKey } from "@/hooks/TranslateService";
import {
  ICompany,
  ICompanyYear,
  ICompanyYearlyParams,
} from "@/store/modules/valored/CompanyModule";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";

interface IResultData {
  label: string;
  label_en: string;
}

export default defineComponent({
  name: "dashboard",
  components: {
    AddCompanySettingModal,
  },
  setup() {
    const { t } = useI18n();

    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const addCompanySettingModalRef =
      ref<InstanceType<typeof AddCompanySettingModal>>();

    const iii = ref(computed(() => store.getters.getIII as IIIInterface));
    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));
    const label_score = ref("");
    const isCompanyCreate = ref<boolean>(false);

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    const last_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.last_yearly_period.value)
    );

    const company_id = reactive(
      computed(() => store.getters.getCompanyYearList.azienda_id)
    );

    const resultScore = reactive(
      computed(() => store.getters.getSurveyPlusScoreResult)
    );
    const rankings = reactive(
      computed(() => store.getters.getSurveyPlusRankings)
    );
    const rank = ref({
      label_en: "",
      label: "",
    });

    // Check if III and/or Survey Plus are in a pending status.
    const isIIIPending = ref(
      computed(() => store.getters.getIsIIIPending as IIIInterface)
    );
    const isSurveyPlusPending = ref(
      computed(() => store.getters.getIsSurveyPlusPending as IIIInterface)
    );

    const surveyUrl = ref(
      computed(() => {
        if (isAdmin.value) {
          return `/admin/survey/${route.params.id}/1`;
        } else {
          return "/survey/1";
        }
      })
    );

    const surveyPlusUrl = ref(
      computed(() => {
        if (isAdmin.value) {
          return `/admin/survey-plus/${route.params.id}/1`;
        } else {
          return "/survey-plus/1";
        }
      })
    )

    const reportUrl = ref(
      computed(() => {
        if (isAdmin.value) {
          return `/admin/report/${route.params.id}`;
        } else {
          return "/report";
        }
      })
    );

    const surveyPlusReportUrl = ref(
      computed(() => {
        if (isAdmin.value) {
          return `/admin/survey-plus/report/${route.params.id}`;
        } else {
          return "/survey-plus/report";
        }
      })
    );

    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
      companyYearlyParams: computed(
        () => store.getters.getCompanyYearlyParams as ICompanyYearlyParams
      ),
    });

    const toYearAdmin = reactive(
      computed(() => {
        return localStorage.getItem("to_year_admin");
      })
    );

    const selectedUser = reactive(
      computed(() => {
        return JSON.parse(
          localStorage.getItem("simulate_user") as string
        );
      })
    );

    const simulatedUserAccountInfo = reactive({
      userProfile: computed(() => {
        return {
          id: selectedUser.value.id,
          user_name: selectedUser.value.user_name,
          email: selectedUser.value.email,
          ruolo_aziendale: selectedUser.value.ruolo_aziendale,
          telefono: selectedUser.value.telefono,
        }
      }),
      companyProfile: computed(() => {
        return {
          denom: selectedUser.value.denom,
          p_iva: selectedUser.value.p_iva,
        }
      }),
      companyYearlyParams: computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year === parseInt(toYearAdmin.value as string)) {
            return yearlyParameters;
          }
        }
        return null;
      }),
    });

    const isOnMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    onMounted(() => {
      setCurrentPageTitle("");

      MenuComponent.reinitialization();

      if (isAdmin.value && route.name === "dashboard") {
        const simulatedUserId = JSON.parse(localStorage.getItem("simulate_user") as string).id;
        router.push(`/admin/${simulatedUserId}/dashboard`);

        store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, localStorage.getItem("to_year_admin"));

        setTimeout(() => {
          window.location.reload();
        }, 250);
      }

      if (!localStorage.getItem("to_year")) {
        localStorage.setItem(
          "to_year",
          optionYearlyPeriod.value.last_yearly_period.toString()
        );
      }
      if (isAdmin.value && !localStorage.getItem("to_year_admin")) {
        let simulatedCompanyYearlyParameters = JSON.parse(localStorage.getItem("simulate_user") as string).yearlyParameters;
        localStorage.setItem(
          "to_year_admin",
          simulatedCompanyYearlyParameters[simulatedCompanyYearlyParameters.length - 1].azienda_yearly_parameters.year.toString()
        );
      }

      if (isAdmin.value) {
        store
          .dispatch(Actions.REQ_COMPANY_LIST_YEAR, route.params.id)
          .then(() => {
            store.dispatch(Actions.REQ_III, {
              year: localStorage.getItem("to_year_admin"),
              user_id: route.params.id,
            });
            store.dispatch(Actions.REQ_III_IS_PENDING, route.params.id);
          }
        );
        store.dispatch(Actions.REQ_SURVEY_PLUS_RANKINGS).then(() => {
          getMyCompanyResult(company_id.value, localStorage.getItem("to_year_admin"));
        });
      } else {
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR)
        .then(() => {
          store.dispatch(Actions.REQ_III, {
            year: localStorage.getItem("to_year"),
          });
          store.dispatch(Actions.REQ_III_IS_PENDING);
        });
        store.dispatch(Actions.REQ_SURVEY_PLUS_RANKINGS).then(() => {
          getMyCompanyResult(company_id.value, localStorage.getItem("to_year"));
        });
      }

      setTimeout(() => {
        if (isAdmin.value) {
          store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, localStorage.getItem("to_year_admin"));
          if (
            !optionYearlyPeriod.value.available_years.includes(
              parseInt(localStorage.getItem("to_year_admin") as string)
            )
          ) {
            addCompanySettingModalRef.value?.onShowModal();
          }
        } else {
          store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, localStorage.getItem("to_year"));
          if (accountInfo.companyYearlyParams?.latest == true) {
            addCompanySettingModalRef.value?.onShowModal();
          }
        }
      }, 1000);

      if (
        isOnMobile &&
        !localStorage.getItem("was_small_screen_warning_checked")
      ) {
        Swal.fire({
          text: t("SMALL_SCREEN_WARNING_MESSAGE"),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-dark-blue"
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            localStorage.setItem(
              "was_small_screen_warning_checked", 
              "true"
            )
          }
        });
      }
    });

    bus.on("onGetCompanyYearlyParams", function (data) {
      if (isAdmin.value) {
        if (
          !optionYearlyPeriod.value.available_years.includes(
            parseInt(localStorage.getItem("to_year_admin") as string)
          )
        ) {
          addCompanySettingModalRef.value?.onShowModal();
        }
      } else {
        if (data.latest == true) {
          isCompanyCreate.value = true;
          addCompanySettingModalRef.value?.onShowModal();
        } else if (data.azienda_yearly_parameters.areSettingsUpdated == 0) {
          isCompanyCreate.value = false;
          addCompanySettingModalRef.value?.onShowModal();
        }
      }
    });

    const surveyPlusResult = ref<IResultData | null>();
    let isLoading = false;

    const getMyCompanyResult = (company_id, year) => {
      isLoading = true;
      surveyPlusResult.value = null;

      store
        .dispatch(Actions.REQ_SURVEY_PLUS_SCORE_RESULT, {
          company_id: company_id,
          year: year,
        })
        .then(() => {
          const score = Math.floor(parseFloat(resultScore.value.ranking));
          const tmp_score = rankings.value.filter((item) => {
            if (item) {
              const rangeArr = item.attributes.range.split("-");
              const fromRange = parseInt(rangeArr[0].replace(/\s/g, ""));
              const toRange = parseInt(rangeArr[1].replace(/\s/g, ""));
              if (score >= fromRange && score <= toRange) {
                return item;
              }
            }
          });

          rank.value = {
            label: tmp_score[0]?.attributes?.label,
            label_en: tmp_score[0]?.attributes?.label_en,
          };

          isLoading = false;
        });
    };

    return {
      translate,
      iii,
      isIIIPending,
      isSurveyPlusPending,
      surveyUrl,
      surveyPlusUrl,
      surveyPlusReportUrl,
      reportUrl,
      last_yearly_period,
      current_yearly_period,
      addCompanySettingModalRef,
      isCompanyCreate,
      surveyPlusResult,
      translateKey,
      isLoading,
      optionYearlyPeriod,
      label_score,
      rank,
      isAdmin,
      localStorage
    };
  },
});
